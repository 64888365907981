import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Row from "../components/layout/row"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const InputStyle = css`
  outline: none;
  padding: 1rem;
  font-size: 1rem;
  background: white;
  border-width: 2px;
  border-style: solid;
  border-color: lightgrey;
  resize: none;
  width: 100%;
  border-radius: 0px;
  transition: border-color 0.5s linear 0s;
  &:focus {
    border-color: #c4281d;
    outline: none;
  }
`

const InputWrapper = styled.div`
  label {
    margin-bottom: 0.3rem;
    font-size: 1rem;
    font-weight: 500;
  }

  textarea {
    margin: 0.5rem 0;
    ${InputStyle}
  }
`

const Input = styled.input`
  ${InputStyle}
  height: 2rem;
  padding: 1rem 0.5rem;
  margin-bottom: 0.7rem;
`

const FormButton = styled.button`
  background-color: transparent;
  font-size: 1.2rem;
  color: ${props => props.theme.colors.activeLink};
  cursor: pointer;
  font-weight: 500;
  padding: 1rem 0;
  outline: none;
  border: none;
  &:disabled {
    color: #db7e77;
    cursor: not-allowed;
  }
`

const CheckBox = styled.input`
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
  }

  // Box hover
  &:hover + label:before {
    background: ${props => props.theme.colors.activeLink};
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: ${props => props.theme.colors.activeLink};
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
`

const StyledForm = styled.form`
  width: 50%;
  ${props => props.theme.tablet} {
    width: 100%;
  }
`

const StatusStyled = styled.span`
  font-weight: 600;
  font-size: 1.2rem;
`

const AgreementWrapper = styled.div`
  p {
    margin: 1rem 0;
  }
`

const FormComponent = ({
  inputs,
  setInputs,
  setStatus,
  setLoading,
  initialInputs,
}) => {
  const [agreement, setAgreement] = useState(false)
  const [buttonDisabled, disableButton] = useState(true)
  const [buttonName, setButtonName] = useState("Отправить сообщение")

  const checkForEmptyValues = () => {
    const inputValues = Object.values(inputs)
    const checkForEmptyString = element => element === ""

    if (!inputValues.some(checkForEmptyString) && agreement) {
      disableButton(false)
    } else {
      disableButton(true)
    }
  }

  useEffect(() => {
    checkForEmptyValues()
  })

  const clearState = () => {
    setInputs({ ...initialInputs })
  }

  function handleSubmit(event) {
    if (event) {
      event.preventDefault()
    }

    disableButton(!buttonDisabled)
    setAgreement(!agreement)
    setButtonName("Сообщение отправляется...")

    const formData = new FormData()

    for (const name in inputs) {
      formData.append(name, inputs[name])
    }

    setLoading(true)

    fetch(
      "https://fond214pk.ru/wp-json/contact-form-7/v1/contact-forms/758/feedback",
      {
        method: "POST",
        body: formData,
      }
    )
      .then(r => r.json())
      .then()
      .then(data => {
        setLoading(false)
        setStatus(data.message)
      })
      .then(() => {
        clearState()
        setButtonName("Отправить сообщение")
      })
  }

  const handleInputChange = event => {
    event.persist()
    const { name, value } = event.target
    setInputs(inputs => ({
      ...inputs,
      [name]: value,
    }))
  }

  const handleAgreementChange = () => {
    setAgreement(!agreement)
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      <InputWrapper>
        <label htmlFor="name">Имя</label>
        <Input
          type="text"
          name="your-name"
          required
          onChange={handleInputChange}
          value={inputs["your-name"]}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="email">Адрес электронной почты</label>
        <Input
          type="email"
          name="your-email"
          required
          onChange={handleInputChange}
          value={inputs["your-email"]}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="phoneNumber">Номер телефона</label>
        <Input
          type="tel"
          name="your-phone"
          required
          onChange={handleInputChange}
          value={inputs["your-phone"]}
        />
      </InputWrapper>
      <InputWrapper>
        <label htmlFor="subject">Тема</label>
        <Input
          type="text"
          name="your-subject"
          required
          onChange={handleInputChange}
          value={inputs["your-subject"]}
        />
      </InputWrapper>
      <InputWrapper>
        <textarea
          name="your-message"
          rows="10"
          placeholder="Ваше сообщение"
          required
          value={inputs["your-message"]}
          onChange={handleInputChange}
        />
      </InputWrapper>
      <AgreementWrapper>
        <CheckBox
          type="checkbox"
          id="agreement"
          name="agreement"
          onChange={handleAgreementChange}
          checked={agreement}
        />
        <label htmlFor="agreement">
          Cогласен (-на) на обработку персональный данных
        </label>
        <p>
          Ставя отметку, я даю свое согласие на обработку моих персональных
          данных в соответствии с законом №152-Ф3 "О персональных данных" от
          27.07.2006 и принимаю условия&nbsp;
          <a
            href="https://code-ya.jivosite.com/script/eula/VraH8RXZJl"
            target="_blank"
            rel="noreferrer"
          >
            Пользовательского соглашения
          </a>
        </p>
      </AgreementWrapper>
      <FormButton type="submit" disabled={buttonDisabled}>
        {buttonName}
      </FormButton>
    </StyledForm>
  )
}

const ContactUs = props => {
  const initialInputs = {
    "your-name": "",
    "your-email": "",
    "your-phone": "",
    "your-subject": "",
    "your-message": "",
  }

  const [inputs, setInputs] = useState({ ...initialInputs })
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)

  return (
    <Layout>
      <Row margin="2rem auto">
        <h1>Задать вопрос</h1>
        <FormComponent
          inputs={inputs}
          setInputs={setInputs}
          status={status}
          setStatus={setStatus}
          loading={loading}
          setLoading={setLoading}
          initialInputs={initialInputs}
        />
        <StatusStyled>{status}</StatusStyled>
      </Row>
    </Layout>
  )
}

export default ContactUs
